.about {
	padding-top: 75px;
	margin-top: -75px;
	margin-bottom: 100px;

	&Text {
		font-size: 1rem;
		color: #fff;
		max-width: 800px;
		white-space: pre-line;
	}
}
