$orange: #ff7a00;
$text-color: #ffffff;
$border-color: #2c3e50;

.table {
	margin: 80px 0;
	color: $text-color;

	&__header {
		margin-bottom: 20px;
	}

	&__logo {
		margin-bottom: 40px;
	}

	&__wrapper {
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		max-height: 400px;
		overflow-y: auto;
		margin-bottom: 50px;
	}

	&__table {
		width: 100%;
		min-width: 600px;
		border-collapse: separate;
		border-spacing: 0 10px;

		th,
		td {
			padding: 15px;
			text-align: left;
			white-space: nowrap;
		}

		th {
			font-weight: bold;
			text-transform: uppercase;
			color: $orange;
			position: sticky; /* Делаем заголовок "липким" */
			top: 0; /* Фиксируем его к верху таблицы */
			background-color: $border-color; /* Цвет фона для заголовка, чтобы он не перекрывался контентом */
			z-index: 1; /* Устанавливаем z-index для приоритета над другими элементами */
		}

		td {
			border-bottom: 1px solid $border-color;
		}
	}
}

@media screen and (max-width: 768px) {
	.table {
		&__wrapper {
			margin: 0 -15px;
			padding: 0 15px;
		}
	}
}
