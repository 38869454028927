.footer {
	position: relative;
	background: url('https://images.unsplash.com/photo-1642239817349-3e1cf98817a5?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')
		no-repeat center center/cover;
	color: #ffffff;
	padding: 50px 0 10px 0;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
	}
	&Content {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: start;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom: 50px;
		gap: 50px;
	}
	&Logo {
		&Block {
			margin-bottom: 20px;
		}
	}
	&Contacts {
		h3 {
			margin-bottom: 20px;
		}
		&List {
			display: flex;
			align-items: start;
			flex-direction: column;
			gap: 10px;
		}
	}
	&Social {
		h3 {
			margin-bottom: 20px;
		}
		&List {
			display: flex;
			align-items: start;
			flex-direction: column;
			gap: 10px;
		}
		&Item {
			a {
				color: #fff;
			}
		}
	}
	&Desc {
		font-size: 16px;
		color: #fff;
		max-width: 300px;
	}
	&Copyright {
		position: relative;
		z-index: 1;
		text-align: center;
	}
}
