.signUp {
	padding: 100px 0 50px 0;
	background-color: #f5f5f5;

	&__content {
		max-width: 700px;
		margin: 0 auto;
		padding: 30px 50px;
		background-color: #ffffff;
		border-radius: 8px;
		margin-bottom: 15px;
		@media (max-width: 768px) {
			padding: 20px 20px;
		}
	}

	&__title {
		font-size: 24px;
		color: #333;
		margin-bottom: 20px;
		text-align: center;
	}

	&__formGroup {
		margin-bottom: 15px;
	}

	&__label {
		display: block;
		margin-bottom: 5px;
		font-weight: bold;
		color: #555;
	}

	&__input {
		width: 100%;
		padding: 10px;
		border: 1px solid #ddd;
		border-radius: 4px;
		font-size: 16px;

		&:focus {
			outline: none;
			border-color: #007bff;
		}
	}

	&__button {
		display: block;
		margin-left: auto;
		padding: 10px 30px;
		background-color: #007bff;
		color: #fff;
		border: none;
		border-radius: 4px;
		font-size: 16px;
		cursor: pointer;
		transition: background-color 0.3s;

		&:hover {
			background-color: #0056b3;
		}
	}
	&__tabs {
		display: flex;
		margin-bottom: 20px;
		justify-content: center;
	}

	&__tab {
		padding: 10px 20px;
		background-color: #f0f0f0;
		border: none;
		cursor: pointer;
		transition: background-color 0.3s;

		&:hover {
			background-color: #e0e0e0;
		}

		&_active {
			background-color: #007bff;
			color: white;
		}
	}

	&__step {
		max-width: 800px;
		margin: 0 auto;
		&Title {
			margin-bottom: 20px;
			font-size: 18px;
		}
	}

	&__navigation {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
	}

	&__nextButton,
	&__prevButton {
		border-radius: 5px;
		padding: 10px 30px;
		background-color: #007bff;
		color: white;
		border: none;
		cursor: pointer;
		transition: background-color 0.3s;

		&:hover {
			background-color: #0056b3;
		}
	}

	&__nextButton {
		display: block;
		margin-left: auto;
	}

	&__prevButton {
		background-color: #6c757d;

		&:hover {
			background-color: #5a6268;
		}
	}

	&__select {
		width: 100%;
		padding: 10px;
		border: 1px solid #ddd;
		border-radius: 4px;
		font-size: 16px;
		appearance: none; // Убирает стандартный вид выпадающего списка
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='%23333' viewBox='0 0 12 12'%3E%3Cpath d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: right 10px center;
		background-size: 12px;

		&:focus {
			outline: none;
			border-color: #007bff;
		}
	}

	&__agreement {
		margin-bottom: 20px;
	}

	&__checkboxGroup {
		display: flex;
		align-items: center;
	}

	&__checkbox {
		margin-right: 10px;
	}

	&__checkboxLabel {
		font-size: 14px;
	}

	&__pdfLink {
		background: none;
		border: none;
		color: #007bff;
		text-decoration: underline;
		cursor: pointer;
		padding: 0;
		font: inherit;
		margin-left: 5px;

		&:hover {
			color: #0056b3;
		}
	}

	&__pdfOverlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	&__pdfContainer {
		background-color: white;
		padding: 20px;
		border-radius: 8px;
		width: 80%;
		height: 80%;
		position: relative;
	}

	&__pdfClose {
		position: absolute;
		top: 10px;
		right: 10px;
		background: none;
		border: none;
		font-size: 16px;
		cursor: pointer;
	}

	&__pdfFrame {
		width: 100%;
		height: calc(100% - 40px);
		border: none;
	}
	&__error {
		color: red;
		font-size: 12px;
		margin-top: 5px;
		display: block;
	}
}
