.header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	background: #111a2371;
	backdrop-filter: blur(20px);
	padding: 20px 0;
	&Block {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.logo {
		&Block {
			display: block;
		}
	}
	&Burger {
		display: none;
		position: relative;
		width: 30px;
		height: 20px;
		&::before,
		&::after {
			content: '';
			background: #fff;
			position: absolute;
			width: 100%;
			height: 2px;
			left: 0;
			transition: 0.3s ease;
		}
		span {
			background: #fff;
			position: absolute;
			width: 100%;
			height: 2px;
			top: 9px;
			left: 0;
			transition: 0.3s ease;
		}
		&::before {
			top: 0;
		}
		&::after {
			bottom: 0;
		}
		@media (max-width: 1040px) {
			display: block;
			position: relative;
			z-index: 10;
		}
		&.active {
			span {
				transform: scale(0);
			}
			&::before {
				top: 9px;
				transform: rotate(45deg);
			}
			&::after {
				bottom: 9px;
				transform: rotate(-45deg);
			}
		}
	}

	&LanguageItem {
		position: relative;
		display: inline-block;
	}

	&LanguageButton {
		background: none;
		border: none;
		color: #fff;
		font-size: 1rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 5px;
	}

	&LanguageDropdown {
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		background: #111a2371;
		backdrop-filter: blur(20px);
		color: #fff;
		list-style: none;
		padding: 10px 0;
		border-radius: 5px;
	}

	&LanguageOption {
		&:hover {
			background-color: #000;
		}

		button {
			padding: 10px 20px;
			color: #fff;
			text-decoration: none;
		}
	}
}

.nav {
	transition: 0.5s ease;
	@media (max-width: 1040px) {
		position: fixed;
		top: 0;
		right: -100%;
		width: 100%;
		min-height: 100vh;
		background: #111a23de;
	}

	&List {
		display: flex;
		align-items: center;
		gap: 60px;
		@media (max-width: 1040px) {
			min-height: 100vh;
			justify-content: center;
			flex-direction: column;
		}
	}
	&Item {
	}
	&Link {
		color: #fff;
		font-size: 16px;
		transition: 0.3s ease;
		&:hover {
			color: #f48020;
		}
		@media (max-width: 798px) {
			font-size: 18px;
		}
	}
	&Login {
		color: #fff;
		font-size: 16px;
		background: #f48020;
		padding: 10px 20px;
		border-radius: 5px;
		border: 1px solid #f48020;
		transition: 0.3s ease;
		&:hover {
			background: transparent;
			color: #f48020;
		}
		@media (max-width: 798px) {
			font-size: 18px;
		}
	}
}
.nav.active {
	right: 0;
}
