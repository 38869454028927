.services {
	margin-bottom: 150px;
	&Grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;

		@media (max-width: 600px) {
			grid-template-columns: 1fr;
		}
	}

	&Item {
		background-color: #f48020;
		border-radius: 5px;
		padding: 10px;
		min-height: 400px;
		transition: transform 0.2s;
		&Iblock {
			margin-bottom: 10px;
			overflow: hidden;
			border-radius: 5px;
		}
		&Img {
			object-fit: cover;
			width: 100%;
		}
		&:hover {
			transform: translateY(-5px);
		}

		&Title {
			font-size: 1.2rem;
			margin-bottom: 10px;
			color: #fff;
		}

		&Text {
			font-size: 1rem;
			color: #fff;
		}
	}
}
