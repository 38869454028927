.hero {
	position: relative;
	min-height: 100vh;
	background: url('https://images.unsplash.com/photo-1511883040705-6011fad9edfc?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')
		no-repeat center center/cover;
	display: flex;
	align-items: center;
	color: white;
	padding: 0 20px;
	margin-bottom: 100px;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
	}

	&Content {
		position: relative;
		z-index: 1;
		max-width: 800px;
	}

	&Title {
		font-size: 2.5rem;
		margin-bottom: 20px;
	}

	&Description {
		font-size: 1.2rem;
		margin-bottom: 30px;
	}

	&Button {
		display: inline-block;
		padding: 13px 35px;
		font-size: 1rem;
		color: #fff;
		background-color: #02519e;
		text-decoration: none;
		border-radius: 5px;
		transition: 0.3s ease;

		&:hover {
			background-color: #4478bb;
		}
	}

	@media (max-width: 768px) {
		&__title {
			font-size: 2rem;
		}

		&__description {
			font-size: 1rem;
		}

		&__button {
			padding: 8px 16px;
			font-size: 0.9rem;
		}
	}
}
