.commodityExchange {
	padding-top: 75px;
	margin-top: -75px;
	margin-bottom: 100px;
	&Nav {
		display: flex;
		margin-bottom: 20px;
		overflow-x: auto;

		@media (max-width: 768px) {
			flex-wrap: wrap;
		}
	}

	&NavItem {
		padding: 10px 15px;
		text-decoration: none;
		color: #fff;
		white-space: nowrap;

		&Active {
			color: #f48020;
			border-bottom: 2px solid #f48020;
		}

		@media (max-width: 768px) {
			flex: 1 1 auto;
			text-align: center;
		}
	}

	&Content {
		display: flex;
		justify-content: space-between;

		@media (max-width: 1024px) {
			flex-direction: column;
		}
	}

	&Table {
		width: 50%;
		border-collapse: collapse;

		@media (max-width: 1024px) {
			width: 100%;
			margin-bottom: 20px;
		}

		th,
		td {
			padding: 10px;
			text-align: left;
			color: #fff;
			border-bottom: 1px solid #e2e2e2ab;
		}

		@media (max-width: 480px) {
			font-size: 14px;

			th,
			td {
				padding: 8px 5px;
			}
		}
	}

	&PriceChange {
		&Up {
			color: #4caf50 !important;
		}

		&Down {
			color: #f44336 !important;
		}
	}

	&Chart {
		width: 45%;

		@media (max-width: 1024px) {
			width: 100%;
		}
	}

	&ChartTitle {
		color: #fff;
		margin-bottom: 10px;
	}

	&ChartOptions {
		margin-bottom: 15px;
		display: flex;
		justify-content: flex-start;

		@media (max-width: 480px) {
			justify-content: space-between;
		}
	}

	&ChartOption {
		padding: 5px 10px;
		background: none;
		border: 1px solid #f48020;
		color: #f48020;
		cursor: pointer;
		margin-right: 10px;

		&:last-child {
			margin-right: 0;
		}

		&Active {
			background: #f48020;
			color: white;
		}

		@media (max-width: 480px) {
			flex: 1;
			text-align: center;
			margin-right: 5px;
			font-size: 14px;
		}
	}
}
