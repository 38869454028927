@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

html {
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	width: 10px;
}

/* Изменить цвет дорожки полосы прокрутки */
::-webkit-scrollbar-track {
	background-color: #111a23;
}

/* Изменить цвет ползунка полосы прокрутки */
::-webkit-scrollbar-thumb {
	background-color: #f48020;
}

body {
	background-color: #111a23;
	font-family: 'Montserrat', sans-serif;

	&.lock {
		overflow: hidden;
	}
}

* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

a,
a:link,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

aside,
nav,
footer,
header,
section,
main {
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-size: inherit;
	font-weight: inherit;
}

ul,
ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img,
svg {
	max-width: 100%;
	height: auto;
}

address {
	font-style: normal;
}

input,
textarea,
button,
select {
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button,
input[type='submit'] {
	display: inline-block;
	box-shadow: none;
	background-color: transparent;
	background: none;
	cursor: pointer;
}

input:focus,
input:active,
button:focus,
button:active {
	outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}

.title {
	color: #fff;
	font-size: 48px;
	margin-bottom: 30px;
}

.wrap {
	overflow-x: hidden;
}
.container {
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 20px;
}
.tabs {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 50px;
	flex-wrap: wrap;
	&_btn {
		padding: 15px 30px;
		border: 2px solid #f48020;
		transition: 0.3s ease;
		color: #f48020;
		&:hover {
			background-color: #f48020;
			color: #fff;
		}
		&.active {
			background-color: #f48020;
			color: #fff;
		}
	}
}
